<template>
  <a class="c-toolbox"
    :href="link">
    <picture v-if="cover && url"
      class="c-toolbox__pic">
      <img :src="`${url}`"
        :alt="`${cover.alt}`"
        :title="cover.license ? cover.license.credit : ''"
        class="c-toolbox__img" />
    </picture>
    <VHeading header="h2" :text="title" />
  </a>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { addCloudinaryUrlParams } from '@/utils'
import VHeading from '@forlagshuset/v-heading'

export default {
  components: {
    VHeading
  },

  props: {
    title: String,
    cover: Object,
    link: String
  },

  setup (props) {
    const url = ref('')

    onMounted(() => {
      if(props.cover) {
        const coverURL = props.cover.file.url
        const param = 'h_150,c_scale/w_150,h_150,c_crop'
        url.value = addCloudinaryUrlParams(coverURL, param)
      }
    })

    return {
      url
    }
  }
}
</script>

<style lang="scss">
.c-toolbox {
  color: $color-black;
  text-decoration: none;
  text-align: center;
}
.c-toolbox__pic {
  width: 100%;
}
.c-toolbox__img {
  display: block;
  margin: 0 auto;
}
</style>