<template>
  <div class="c-tools u-px">
    <FHBreadcrumbs v-if="crumbs"
      class="c-tools__breadcrumbs u-py u-px"
      :crumbs="crumbs" />
    <Logotype class="c-tools__bg u-py u-px" />
    <div class="c-tools__content">
      <div class="c-tools__item"
        v-for="item in content"
        :key="item.id">
        <ToolBox 
          :title="mappedData[componentNameNormalize(item.__component)].title(item)"
          :cover="mappedData[componentNameNormalize(item.__component)].cover(item)"
          :link="mappedData[componentNameNormalize(item.__component)].link(item, appSlug)"
          :target="mappedData[componentNameNormalize(item.__component)].target" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { fetchUnitBySlug } from '@/services/strapi'
import { addCloudinaryUrlParams, componentNameNormalize } from '@/utils'
import FHBreadcrumbs from '@forlagshuset/v-breadcrumbs'
import ToolBox from '@/components/ToolBox'
import Logotype from '@/assets/svg/Logotype'



export default {
  components: {
    FHBreadcrumbs,
    ToolBox,
    Logotype
  },

  setup (props, ctx) {
    const appSlug = computed(() => ctx.root.$options.router.history.current.params.slug)
    const title = ref('')
    const crumbs = ref([])
    const content = ref([])

    const mappedData = {
      ComponentLearningElementArticleElement: {
        title: (item) => item.article_type.name,
        link: (item, unit) => `/article/${unit}/${item.article_type.slug}`,
        cover: item => item.article_type.cover? item.article_type.cover.image_type : null,
        target: '_parent'
      },
      ComponentLearningElementImageElement: {
        title: (item) => item.image_type ? item.image_type.name: '',
        link: () => 'https://skriveverktoy.app.fagbokforlaget.no/#/',
        cover: item => item.image_type,
        target: '_blank'
      }
    }

    onMounted(async () => {
      const unit = await fetchUnitBySlug(appSlug.value)

      title.value = unit.name

      crumbs.value.push({
        type: 'tools',
        name: title.value,
        slug: unit.slug
      })

      content.value = unit.content
      content.value.forEach(element => {
        if(element.cover) {
          const coverURL = element.cover.image_type.file.url
          const param = 'h_245,c_scale'
          element.cover.url = addCloudinaryUrlParams(coverURL, param)
          element.cover.alt = element.cover.image_type.altText
        }
      });
    })

    return {
      appSlug,
      title,
      crumbs,
      content,
      mappedData,
      componentNameNormalize
    }
  }
}
</script>

<style lang="scss">
.c-tools {
  position: relative;
  min-height: calc(100vh - 14rem);
}
.c-tools__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right:0;
}
.c-tools__breadcrumbs {
  font-family: CircularPro, sans-serif;
  position: relative;
  z-index: 1;
  .c-breadcrumbs__home {
    display: none;
  }
  .c-breadcrumbs__link {
    color: $color-black;
    &:hover {
      text-decoration: none;
    }
  }
}
.c-tools__content {
  @include bp(675px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  position: relative;
  z-index: 1;
}
.c-tools__item {
  flex-basis: 33%;
}
</style>